/* eslint-disable vue/no-v-html */
<template>
  <div class="resize">
    <div class="l-col">
      <label class="typo__label standart-text">
        <p v-html="$t('server.label', { name: name, id: instance.id })" class="tabulation-base"></p>
      </label>
      <!--      <label class="typo__label big-title">{{ ' ' + name }}</label>-->
    </div>
    <div v-if="isDeleteDisk && isDeleteDisk.length > 0" class="l-col">
      <p>
        <label class="typo__label small-title underline">{{ $t('server.encore') }}</label>
      </p>
      <div>
        <label class="typo__label standart-text">
          <p
            v-html="
              $t('server.disk', {
                name: isDeleteDisk[0].name,
                size: isDeleteDisk[0].size,
                id: isDeleteDisk[0].id,
              })
            "
            class="tabulation"
          ></p>
        </label>
      </div>
      <div v-if="fixed.length">
        <div v-for="ip in fixed" :key="ip.id">
          <label class="typo__label standart-text">
            <p v-html="$t('server.fixed', { ip: ip.ip, name: ip.name })" class="tabulation"></p>
          </label>
        </div>
      </div>
      <div v-if="sharedIp.length">
        <div v-for="ip in sharedIp" :key="ip.ip">
          <label class="typo__label standart-text">
            <p
              v-html="
                $t('server.publicPort', {
                  ip: ip.ip,
                  name: publicSubnetName(ip.name),
                })
              "
              class="tabulation"
            ></p>
          </label>
        </div>
      </div>
    </div>
    <div v-if="undelete" class="l-col">
      <p>
        <label class="typo__label medium-title underline">{{ $t('server.undelete') }}</label>
      </p>
      <div v-if="isUndeleteDisk">
        <div v-for="disk in isUndeleteDisk" :key="disk.name">
          <label class="typo__label standart-text">
            <p
              v-html="
                $t('server.disk', {
                  name: disk.name,
                  size: disk.size,
                  id: disk.id,
                })
              "
              class="tabulation"
            ></p>
          </label>
        </div>
      </div>
      <div v-if="shared">
        <div v-for="net in shared" :key="net.name">
          <label class="typo__label standart-text">
            <p v-html="$t('server.publicNet', { name: subnetName(net) })" class="tabulation"></p>
          </label>
        </div>
      </div>
      <div v-if="float.length">
        <div v-for="ip in float" :key="ip.ip">
          <label class="typo__label standart-text">
            <p v-html="$t('server.float', { ip: ip.ip })" class="tabulation"></p>
          </label>
          <!--          <label class="typo__label medium-title">{{ 'плавающий IP адрес - ' + ip.ip }}</label>-->
        </div>
      </div>
    </div>
    <div v-if="isUndeleteDisk.length > 0 || shared.length > 0 || float.length > 0" class="l-col">
      <label class="typo__label standart-text"
        >Эти ресурсы будут по-прежнему тарифицироваться.</label
      ><br />
      <label class="typo__label standart-text"
        >Удалить их можно будет в соответствующих разделах.</label
      >
    </div>
    <div v-if="flavorCost > 0" class="l-col medium-text">
      <label class="typo__label standart-text">
        <p v-html="summaryCost"></p>
      </label>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'DeleteServer',
  components: {},
  mixins: [setFocus],
  props: {
    name: {
      type: String,
      default: '',
    },
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      shared: [],
      networkId: '',
      // subnets
      private: [],
      float: [],
      fixed: [],
      sharedIp: [],
      cost: 0,
    };
  },
  computed: {
    disks() {
      return this.instance['os-extended-volumes:volumes_attached'].length
        ? this.instance['os-extended-volumes:volumes_attached'].map(id => {
            return {
              name:
                this.$store.state.moduleStack.volumes.find(disk => disk.id === id.id).name ||
                this.$store.state.moduleStack.volumes.find(disk => disk.id === id.id).id,
              size: this.$store.state.moduleStack.volumes.find(disk => disk.id === id.id).size,
              deletable: id.delete_on_termination
                ? !this.$store.state.moduleStack.snapshots.find(x => x.volume_id === id.id)
                : id.delete_on_termination,
              id: this.$store.state.moduleStack.volumes.find(disk => disk.id === id.id).id,
              snap: !!this.$store.state.moduleStack.snapshots.find(x => x.volume_id === id.id),
              deleteAndSnap: id.delete_on_termination,
              type: this.$store.state.moduleStack.volumes.find(disk => disk.id === id.id)
                .volume_type,
              price:
                this.$store.state.moduleStack.price.length > 0
                  ? this.$store.state.moduleStack.price.find(
                      x =>
                        x.intname ===
                        `volume_${
                          this.$store.state.moduleStack.volumes.find(disk => disk.id === id.id)
                            .volume_type
                        }`
                    )[dev]
                  : 0,
            };
          })
        : [];
    },
    flavorCost() {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === 'root_gb')[dev] *
            this.instance.flavor.disk +
            (this.$store.state.moduleStack.price.find(x => x.intname === 'memory')[dev] *
              this.instance.flavor.ram) /
              1024 +
            this.$store.state.moduleStack.price.find(x => x.intname === 'vcpus')[dev] *
              this.instance.flavor.vcpus
        : 0;
    },
    isDeleteDisk() {
      return this.disks && this.disks.length > 0 ? this.disks.filter(x => x.deletable) : [];
    },
    isUndeleteDisk() {
      return this.disks && this.disks.length > 0 ? this.disks.filter(x => !x.deletable) : [];
    },
    publicNetwork() {
      return Object.keys(this.instance.addresses);
    },
    // networkId() {},
    networks() {
      return this.$store.state.moduleStack.networks;
    },
    undelete() {
      return (
        (this.isUndeleteDisk && this.isUndeleteDisk.length > 0) ||
        (this.shared && this.shared.length > 0) ||
        (this.float && this.float.length > 0)
      );
    },
    summaryCost() {
      const cost =
        this.isDeleteDisk.length === 0
          ? this.flavorCost
          : this.flavorCost + this.isDeleteDisk[0].price * this.isDeleteDisk[0].size;

      return this.$t('cost', {
        cost: this.$n(cost, 'currency', this.$i18n.locale),
      });
    },
    ports() {
      return this.$store.state.moduleStack.ports;
    },
  },
  watch: {
    publicNetwork: {
      handler: function (event) {
        // console.log(event);
        if (event) {
          this.networkId = this.$store.state.moduleStack.networks.find(x => x.name === event[0]).id;
        }
        // this.GetProducts();
      },
      immediate: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const net = Object.keys(this.instance.addresses);
      net.forEach(x => {
        // console.log(x);
        this.$store.state.moduleStack.networks.find(network => network.name === x).shared
          ? this.shared.push({
              name: x,
              id: this.$store.state.moduleStack.networks.find(net => net.name === x).id,
            })
          : this.private.push({
              name: x,
            });
      });
      if (this.shared.length) {
        this.shared.forEach(x => {
          this.instance.addresses[x.name].forEach(net => {
            this.shared.find(ip => ip.id === x.id).ip = net.addr;
            this.sharedIp.push({
              ip: net.addr,
              net: x.id,
              name: x.name,
            });
          });
        });
      }
      const float = [];
      if (this.instance.addresses && this.private)
        Object.keys(this.instance.addresses).forEach(x => {
          this.private.find(net => {
            if (net.name === x) {
              this.instance.addresses[x].forEach(addr => {
                addr['OS-EXT-IPS:type'] === 'floating'
                  ? float.push({
                      ip: addr.addr,
                      name: net.name,
                    })
                  : null;
              });
            }
          });
        });
      this.float = float;
      const fixed = [];
      if (this.instance.addresses && this.private)
        Object.keys(this.instance.addresses).forEach(x => {
          this.private.find(net => {
            if (net.name === x) {
              this.instance.addresses[x].forEach((addr, i) => {
                if (addr['OS-EXT-IPS:type'] === 'fixed') {
                  fixed.push({
                    ip: addr.addr,
                    name: net.name,
                    id: addr.addr + net.name,
                  });
                  this.$emit('change', addr);
                }
              });
            }
          });
        });
      this.fixed = fixed;
    },
    publicSubnetName(item) {
      return item.startsWith('public_') ? 'публичная подсеть' : item;
    },
    subnetName(port) {
      const ports = this.$store.state.moduleStack.ports
        .filter(x => x.network_id === port.id)
        .filter(x => x.device_id === this.instance.id);
      let subnetName;
      ports.forEach(x => {
        x.fixed_ips.forEach(po => {
          if (po.ip_address === port.ip) {
            subnetName = this.$store.state.moduleStack.subnets.find(
              x => x.id === po.subnet_id
            ).name;
          }
        });
      });
      return subnetName;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "cost": "Расходы уменьшатся на <b>%{cost}</b> в час.",
    "disk": {
      "label": "Новый пароль для сервера",
      "description" : "Текстовое описание"
    },
    "server": {
      "label": "Удалить сервер: <b>%{name}</b> (id: %{id})?",
      "disk": "диск: <b>%{name}</b> (<b>%{size}ГБ</b>) (id: %{id})",
      "fixed": "порт: <b>%{ip}</b> (%{name})",
      "float": "плавающий IP адрес: <b>%{ip}</b>",
      "publicPort": "порт: <b>%{ip}</b> (%{name})",
      "publicNet": "публичная подсеть: <b>%{name}</b>",
      "encore": "Вместе с сервером удалятся:",
      "undelete": "Останутся без изменения:",
      "text": "Эти ресурсы будут по-прежнему тарифицироваться. Удалить их можно будет в соответствующих разделах.",
      "description" : "Текстовое описание"
    },
    "sure": {
      "confirm": "Удалить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: ellipsis;
  overflow: auto;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
.tabulation-base{

  text-indent: -6.7rem;
  padding-left: 6.7rem;
}
  .tabulation{

    text-indent: -2.3rem;
    padding-left: 2.3rem;
  }
  .underline {
    text-decoration underline red;
    //color: $error
  }
</style>
